import React from 'react'

// Components
import PrivateNav from '../components/PrivateNav';
import NavBar from '../../components/NavBar';
import PayDuesContainer from '../components/PayDuesContainer';
import Footer from '../../components/Footer';

const PayDues = (props) => {
    return (
        <div>
            {props.auth ? (
                <PrivateNav/>
            ) : (
                <NavBar auth={props.auth}/>
            )}
            <PayDuesContainer/>
            <Footer/>
        </div>
    )
}

export default PayDues