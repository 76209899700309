import React from 'react'

// Components
import NavBar from '../components/NavBar'
import ResetPassword from '../components/ResetPassword';
import Footer from '../components/Footer';

const Home = (props) => {
    return (
        <div>
            <NavBar auth={props.auth}/>
            <ResetPassword/>
            <Footer/>
        </div>
    )
}

export default Home