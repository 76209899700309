import React, { useState } from "react";
import {Form, Button} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { Auth } from "aws-amplify";
import {sha256 } from 'js-sha256';

import "./SignupStyles.css";

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        alphaMuPassCode: "",
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
    });
    let navigate = useNavigate();
    const [newUser, setNewUser] = useState(null);
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword &&
            fields.alphaMuPassCode.length > 0
        );
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            // add api call to check alpha mu passcode on backend and return
            if (sha256(fields.alphaMuPassCode) != "61374f6557b9ab4fbd76274870ebad9994708c4697b7efca771b1ff202e2210b") {
                throw new Error("Incorrect Alpha Mu Passcode. Please try again.");
            }
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
            });
            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);
        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);

            userHasAuthenticated(true);
            navigate("/connect");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function renderConfirmationForm() {
        return (
            <>
                <Form onSubmit={handleConfirmationSubmit}>
                    <Form.Group controlId="confirmationCode" size="lg">
                        <Form.Label>Confirmation Code</Form.Label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            onChange={handleFieldChange}
                            value={fields.confirmationCode}
                        />
                        <Form.Text muted>Please check your email for the code.</Form.Text>
                    </Form.Group>
                    <LoaderButton
                        block
                        size="lg"
                        type="submit"
                        variant="success"
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm()}
                    >
                        Verify
                    </LoaderButton>
                </Form>
            </>
        );
    }

    function renderForm() {
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email" size="lg" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="password" size="lg" className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="confirmPassword" size="lg" className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        onChange={handleFieldChange}
                        value={fields.confirmPassword}
                    />
                </Form.Group>
                <Form.Group controlId="alphaMuPassCode" size="lg" className="mb-3">
                    <Form.Label>Alpha Mu Passcode</Form.Label>
                    <Form.Control
                        autoFocus
                        type="password"
                        value={fields.alphaMuPassCode}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                    className="mb-3"
                >
                    Signup
                </LoaderButton>
                <p>Already registered? <Button size="sm" as={Link} to="/brother-portal">Login here!</Button></p>
            </Form>
        );
    }

    return (
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
}