import React from 'react'

// styles
import "./GalleryImagesStyles.css"

// components


const GalleryImages = () => {

    const getImagePaths = (directory) => {
        let images = [];
        directory.keys().map((item, index) => images.push(item.replace("./", "")));
        return images;
    }

    const directory = require.context("../assets/gallery", false, /\.(png|jpe?g|svg)$/);
    let imagePaths = getImagePaths(directory);

    let images = []
    imagePaths.map((path) => images.push(require("../assets/gallery/" + path)));

    return (
        <div id="gallery-container">
            {images.map((img, index) => (
                <img key={index} src={img} alt={img} className='gallery_image'/>
            ))}
        </div>
    );

    // const importAll = require =>
    //     require.keys().reduce((acc, next) => {
    //         acc[next.replace("./", "")] = require(next);
    //         return acc;
    //     }, []);


    // const images = importAll(
    //     require.context("../assets/gallery", false, /\.(png|jpe?g|svg)$/)
    // );

    // return (
    //     <div style={{height: "50vh"}}>
    //         {images.map(image=>{
    //             return <p>test</p>
    //         })}
    //     </div>
    // )
}

export default GalleryImages
