import React from 'react'

// Components
import NavBar from '../components/PrivateNav';
import SlackContainer from '../components/SlackContainer';
import ConnectContainer from '../components/ConnectContainer';
import Footer from '../../components/Footer';

const Home = (props) => {
    return (
        <div>
            <NavBar auth={props.auth}/>
            {/* <SlackContainer/> */}
            <ConnectContainer/>
            <Footer/>
        </div>
    )
}

export default Home