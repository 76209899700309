import React from 'react'

// styles
import "./FooterStyles.css";

// components
import Crest from '../assets/crest.png';
import AlphaMuLogo from '../assets/alpha_mu_logo.png';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div id='footer_container' variant='dark'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3 col-sm-6 footer_section'>
                        <h4>Kappa Sigma Alpha Mu</h4>
                        <div id="footer_logo_container">
                            <img src={Crest} className="crest_footer" />
                            <img src={AlphaMuLogo} className="crest_footer" />
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 footer_section'>
                        <h4>Contact Us</h4>
                        <ul className='list-unstyled'>
                            <li>Address: 204 West Cameron Ave.</li>
                            <li>Chapel Hill, NC 27514</li>
                            <br />
                            <li></li>
                            <li>Email: freddystanley20@gmail.com</li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-6 footer_section'>
                        <h4>Important Links</h4>
                        <ul className='list-unstyled'>
                            <li><Link to="/calendar">Calendar</Link></li>
                            <li><Link to="/gallery">Gallery</Link></li>
                            <li><Link to="/brother-portal">Brother Portal</Link></li>
                            <li><a href="https://www.paypal.com/donate/?hosted_button_id=63DCY8EVYAQLC">Donations</a></li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-6 footer_section'>
                        <h4>Social Media</h4>
                        <ul className='list-unstyled' id="social_media_icons">
                            <li><a target="_blank" href='https://www.instagram.com/kappasigmaunc/'><FaInstagram id="ig_icon" /></a></li>
                            <br />
                            <li><a target="_blank" href="https://www.linkedin.com/groups/12444112/"><FaLinkedin id="linkedin_icon" /></a></li>
                        </ul>
                    </div>
                    <div id='footer_bottom'>
                        <p className='text-xs-center'>&copy; Copyright {new Date().getFullYear()} Alpha Mu Chapter of the Kappa Sigma Fraternity</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
