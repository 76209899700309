import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import "./PayDuesContainerStyles.css"


const PayDuesContainer = () => {

    const [amount, setAmount] = useState(0);
    const currency = "USD";
    const style = { "layout": "vertical" };

    const validateForm = () => {
        return amount != 0;
    }

    const ButtonWrapper = ({ currency, showSpinner }) => {
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

        useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    currency: currency,
                },
            });
        }, [currency, showSpinner]);

        return (
            <>
                {(showSpinner && isPending) && <div className="spinner" />}
                <PayPalButtons
                    style={style}
                    disabled={!validateForm()}
                    forceReRender={[currency, style]}
                    fundingSource={undefined}
                    createOrder={(data, actions) => {
                        return actions.order
                            .create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: currency,
                                            value: amount,
                                        },
                                    },
                                ],
                            })
                            .then((orderId) => {
                                return orderId;
                            });
                    }}
                    onApprove={function (data, actions) {
                        return actions.order.capture().then(function () {

                        });
                    }}
                />
            </>
        );
    }


    return (
        <div id="paypal_container">
            
            <Form>
                
                <Form.Group className='mb-3'>
                    <Form.Select id="selectDues" onChange={(e) => setAmount(e.target.value)}>
                        <option value={0}>Select Dues Plan</option>
                        <option value={2300}>Pledge Dues – $2,300</option>
                        <option value={2600}>Out-Of-House Dues – $2,600</option>
                        <option value={5000}>In-House Dues – $5,000</option>
                    </Form.Select>
                </Form.Group>
            </Form>
            <PayPalScriptProvider
                options={{
                    "client-id": "AbOmpRrmDi2IQPRZGC4rXrQlpZkoc1ab1OBclDAdDhjwj2TFiKsPp7Ive2L8Xk7DwoRCLawSPpv_JJFf",
                    components: "buttons",
                    currency: "USD",
                    // "disable-funding": "credit",
                }}
            >
                <ButtonWrapper
                    currency={currency}
                    showSpinner={false}
                />
            </PayPalScriptProvider>
        </div >
    );
}

export default PayDuesContainer