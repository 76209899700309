import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import "./ErrorContainerStyles.css"

const ErrorContainer = () => {
    return (
        <div id="error_container">
            <h1>404 Error</h1>
            <p>Sorry, this page doesn't exist!</p>
            <Button as={Link} to="/">Return Home</Button>
        </div>
    )
}

export default ErrorContainer
