import React from 'react'

// Components
import NavBar from '../components/NavBar'
import HistoryContainer from '../components/HistoryContainer';
import Footer from '../components/Footer';

const History = (props) => {
    return (
        <div>
            <NavBar auth={props.auth}/>
            <HistoryContainer/>
            <Footer/>
        </div>
    )
}

export default History