import React, { useState } from "react";
import { Auth, auth0SignInButton } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import {
    Form,
    FormText,
    FormGroup,
    FormControl,
    FormLabel,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import "./ChangeEmailContainerStyles.css";

export default function ChangeEmailContainer() {
    let navigate = useNavigate();
    const [codeSent, setCodeSent] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: "",
    });
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateEmailForm() {
        return fields.email.length > 0;
    }

    function validateConfirmForm() {
        return fields.code.length > 0;
    }

    async function handleUpdateClick(event) {
        event.preventDefault();
        setIsSendingCode(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, { email: fields.email });
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event) {
        event.preventDefault();
        setIsConfirming(true);
        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);
            navigate("/settings");
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    }

    function renderUpdateForm() {
        return (
            <Form onSubmit={handleUpdateClick}>
                <FormGroup controlId="email" className="mb-3">
                    <FormLabel>New Email</FormLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <LoaderButton
                    type="submit"
                    isLoading={isSendingCode}
                    disabled={!validateEmailForm()}
                    className="mb-3"
                >
                    Update Email
                </LoaderButton>
            </Form>
        );
    }

    function renderConfirmationForm() {
        return (
            <Form onSubmit={handleConfirmClick} id="confirm">
                <FormGroup controlId="code" className="mb-3">
                    <FormLabel>Confirmation Code</FormLabel>
                    <FormControl
                        autoFocus
                        type='tel'
                        value={fields.code}
                        onChange={handleFieldChange}
                    />
                    <FormGroup className="mb-3">
                        <FormText>
                            Please check your email ({fields.email}) for the confirmation code.
                        </FormText>
                    </FormGroup>
                    <LoaderButton
                        className="mb-3"
                        type="submit"
                        isLoading={isConfirming}
                        disabled={!validateConfirmForm()}
                    >
                        Confirm
                    </LoaderButton>
                </FormGroup>
            </Form>
        );
    }

    return (
        <div className="ChangeEmail">
            {!codeSent ? renderUpdateForm()
                : renderConfirmationForm()
            }
        </div>
    );
}