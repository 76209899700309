import React from 'react'

// Components
import PrivateNav from '../components/PrivateNav';
import ChangeEmailContainer from '../components/ChangeEmailContainer';
import Footer from '../../components/Footer';

const Settings = (props) => {
    return (
        <div>
            <PrivateNav/>
            <ChangeEmailContainer/>
            <Footer/>
        </div>
    )
}

export default Settings