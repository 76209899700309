import React from 'react'

// Components
import NavBar from '../components/NavBar'
import HomeCarousel from '../components/HomeCarousel'
import Footer from '../components/Footer';
import Video from '../components/Video';

const Home = (props) => {
    return (
        <div>
            <NavBar auth={props.auth}/>
            <Video/>
            <HomeCarousel/>
            <Footer/>
        </div>
    )
}

export default Home
