import React from 'react'

// Components
import NavBar from '../components/NavBar'
import ErrorContainer from '../components/ErrorContainer';
import Footer from '../components/Footer';

const ErrorPage = (props) => {
    return (
        <div>
            <NavBar auth={props.auth}/>
            <ErrorContainer/>
            <Footer/>
        </div>
    )
}

export default ErrorPage