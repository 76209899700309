import React from 'react';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import "./AlumniDatabaseContainerStyles.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#cccccc',
    },
    secondary: {
      main: '#ac202d',
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

const AlumniDatabaseContainer = () => {
    return (
        <div>
            <ThemeProvider theme={theme}>
            <Fab variant="extended" target="_blank" href="https://docs.google.com/spreadsheets/d/15hfC1F2B60CwA2rKjIJdiRe-xGwoXptkp8HCwRHZv-E/edit?usp=sharing" id="link" color="primary">
                <NavigationIcon sx={{ mr: 1 }} />
                View in Google Sheets
            </Fab>
            </ThemeProvider>
            <iframe id="db" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vReofMMujFoyCC_AMcE35P5JihUpjhS6RlwhzxlqFSouTd1ibv97If0U0W2hvG5IbaA4qgX0ZOHTeYO/pubhtml?widget=true&amp;headers=false"></iframe>
        </div>
    )
}

export default AlumniDatabaseContainer
