import React, { useState } from 'react'
import { Link } from 'react-router-dom';

// styles
import "./NavBarStyles.css";

// components
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Crest from '../assets/crest.png';
import AlphaMuLogo from '../assets/alpha_mu_logo.png';

const NavBar = (props) => {
    return (
        <Navbar bg="dark" variant='dark' sticky="top" expand="lg">
            <div id="header_container">
                <Link to="/"><img src={Crest} id="header_logo" /></Link>
                <div id="header_title">
                    <h1>Kappa Sigma Fraternity</h1>
                    <h2>Alpha Mu Chapter | UNC Chapel Hill</h2>
                </div>
            </div>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link as={Link} to="/chapter-updates">Chapter Updates</Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                        <Nav.Link as={Link} to="/calendar">Calendar</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                        <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="About">
                        {/* <NavDropdown.Item as={Link} to="/about/leadership">Leadership</NavDropdown.Item> */}
                        <NavDropdown.Item as={Link} to="/about/history">History</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Item>
                        <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                        <Nav.Link href="https://www.paypal.com/donate/?hosted_button_id=63DCY8EVYAQLC">Donations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        {props.auth ? (
                            <Nav.Link as={Link} to="/connect">Brother Portal</Nav.Link>
                        ) : (
                            <Nav.Link as={Link} to="/brother-portal">Brother Portal</Nav.Link>
                        )}
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar
