import React, { useState } from 'react'

// styles
import "./HomeCarouselStyles.css"

// components
import { Carousel } from 'react-bootstrap';
import OldInformal from '../assets/old_informal.jpeg';
import HouseWithFallTree from '../assets/house_with_fall_tree.jpeg';
import TomsonBand from '../assets/tomson_band.jpg';
import Informal2021 from '../assets/informal_2021.jpg';

const HomeCarousel = () => {

    return (
        <div id="outer_container">
            <Carousel variant='dark' id='carousel_container'>
                <Carousel.Item interval={5000}>
                    <img
                        className="d-block w-100"
                        src={OldInformal}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <img
                        className="d-block w-100"
                        src={HouseWithFallTree}
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <img
                        className="d-block w-100"
                        src={Informal2021}
                        alt="Third slide"
                    />
                    {/* <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default HomeCarousel
