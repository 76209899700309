import React, { useState } from 'react'
import { Link } from 'react-router-dom';

// styles
import "./PrivateNavStyles.css";

// components
import { Nav, Navbar } from 'react-bootstrap';
import Crest from '../../assets/crest.png';

const PrivateNav = (props) => {
    return (
        <Navbar bg="dark" variant='dark' sticky="top" expand="lg">
            <div id="header_container">
                <Link to="/"><img src={Crest} id="header_logo" /></Link>
                <div id="header_title">
                    <h1>Kappa Sigma Fraternity</h1>
                    <h2>Alpha Mu Chapter | UNC Chapel Hill</h2>
                </div>
            </div>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/pay">Pay Dues</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/alumni">Alumni Database</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/connect">Connect</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/settings">Settings</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default PrivateNav
