import React from 'react'
import history_hero_img from "../assets/history_house.png"
import stacy from "../assets/stacy.jpeg"
import carmichael from "../assets/carmichael.jpeg"
import fetzer from "../assets/fetzer.jpg"
import beard from "../assets/beard.jpg"
import woolen from "../assets/woolen.jpeg"
import cobb from "../assets/cobb.jpeg";

// styles
import "./HistoryContainerStyles.css"


const HistoryContainer = () => {
    return (
        <div id="history_container">
            <div id="top">
                <h1>Alpha Mu History</h1>
                <p>
                    The Alpha Mu Chapter of Kappa Sigma was established at the University of North Carolina in 1893. Its founding members were George Roscoe Little, Gerard Samuel Wittson, James Spenser Lewis, Thomas Pleasant Braswell, Jr., and Thomas Menan Hooker. Since then, Kappa Sigma has established itself as a premier fraternity at the University of North Carolina at Chapel Hill. In the 1930’s Kappa Sigma built their house at 204 West Cameron Avenue which the chapter still calls home today.
                </p>
                <img src={history_hero_img} />
                <p>
                    Alpha Mu has prided itself with recruiting gentlemen of honor and courage that are leaders at the University and beyond. Many Alpha Mu’s have gone on to be legendary figures on campus. Today, there are five buildings on campus named after Alpha Mu alumni. These buildings include:
                </p>
            </div>
            <div id="buildings">
                <div>
                    <h4>Stacy Residence Hall</h4>
                    <img src={stacy} />
                </div>
                <div>
                    <h4>Carmichael Auditorium</h4>
                    <img src={carmichael} />
                </div>
                <div>
                    <h4>Fetzer Field</h4>
                    <img src={fetzer} />
                </div>
                <div>
                    <h4>Beard Hall</h4>
                    <img src={beard} />
                </div>
                <div>
                    <h4>Woolen Gym</h4>
                    <img src={woolen} />
                </div>
            </div>
            <div id="cobb">
                <p>
                    Alpha Mu has also shined in athletics, with brothers being prominent athletes during their time in Chapel Hill. Brother Jack Cobb pledged the Alpha Mu Fraternity in the fall of 1923 and went on to be a 3 time All-American and the 1926 National Player of the Year. Cobb’s jersey is one of only eight retired jerseys by UNC and still hangs in the rafters of the Dean Smith Center. Below is a photo of Cobb with the 1923-1924 UNC mens basketball team.
                </p>
                <img src={cobb}/>
            </div>
        </div>
    )
}

export default HistoryContainer
