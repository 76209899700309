import React from 'react'

// Components
import NavBar from '../components/NavBar'
import Login from '../components/Login';
import Footer from '../components/Footer';

const BrotherPortal = (props) => {
    return (
        <div>
            <NavBar
                auth={props.auth}
            />
            <Login/>
            <Footer/>
        </div>
    )
}

export default BrotherPortal