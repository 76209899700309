import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./lib/errorLib";
import {Button} from "react-bootstrap";

// Public Routes
import Home from './routes/Home';
import Donations from './routes/Donations';
import Contact from './routes/Contact';
import Leadership from './routes/Leadership';

import History from './routes/History';
import Gallery from './routes/Gallery';
import Calendar from './routes/Calendar';
import ChapterUpdates from './routes/ChapterUpdates';
import BrotherPortal from './routes/BrotherPortal';
import ErrorPage from './routes/ErrorPage';
import SignupPage from "./routes/SignupPage";
import Reset from "./routes/Reset";

// Private Routes
import BrotherHome from "./private/routes/BrotherHome";
import PayDues from "./private/routes/PayDues";
import AlumniDatabase from "./private/routes/AlumniDatabase";
import Settings from "./private/routes/Settings";
import ChangePassword from './private/routes/ChangePassword';
import ChangeEmail from "./private/routes/ChangeEmail";
import Connect from "./private/routes/Connect";


function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    navigate("/brother-portal");
  }

  return (
    !isAuthenticating && (
      <>
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Home auth={isAuthenticated}/>} />
        {/* <Route path="/chapter-updates" element={<ChapterUpdates  auth={isAuthenticated}/>} /> */}
        {/* <Route path="/calendar" element={<Calendar  auth={isAuthenticated}/>} /> */}
        <Route path="/gallery" element={<Gallery  auth={isAuthenticated}/>} />
        {/* <Route path="/about/leadership" element={<Leadership  auth={isAuthenticated}/>} /> */}
        <Route path="/about/history" element={<History  auth={isAuthenticated}/>} />
        {/* <Route path="/contact" element={<Contact  auth={isAuthenticated}/>} /> */}
        <Route path="/brother-portal" element={<BrotherPortal auth={isAuthenticated}/>} />
        <Route path="/signup" element={<SignupPage auth={isAuthenticated}/>}/>
        <Route path="/reset-password" element={<Reset/>}/>
        {/* hidden routes */}
        <Route path="/pay" element={<PayDues auth={isAuthenticated}/>}/>
         {/*private routes  */}
         {isAuthenticated? (<>
          <Route path="/brother-home" element={<BrotherHome/>}/>
          <Route path="/alumni" element={<AlumniDatabase/>}/>
          <Route path="/connect" element={<Connect/>}/>
          <Route path="/settings" element={<Settings logout={handleLogout}/>}/>
          <Route path="/settings/password" element={<ChangePassword/>}/>
          <Route path="/settings/email" element={<ChangeEmail/>}/>
         </>): <></>}
        {/* 404 route */}
        <Route path="*" element={<ErrorPage/>} />
      </Routes>
    </AppContext.Provider>
    </>
    )
  );
}

export default App;
