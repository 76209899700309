import React from 'react'

// Components
import PrivateNav from '../components/PrivateNav';
import ChangePasswordContainer from '../components/ChangePasswordContainer';
import Footer from '../../components/Footer';

const Settings = (props) => {
    return (
        <div>
            <PrivateNav/>
            <ChangePasswordContainer/>
            <Footer/>
        </div>
    )
}

export default Settings