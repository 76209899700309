import React from 'react'

// Components
import PrivateNav from '../components/PrivateNav';
import HomeCarousel from '../../components/HomeCarousel';
import Footer from '../../components/Footer';

const BrotherPortal = () => {
    return (
        <div>
            <PrivateNav/>
            <HomeCarousel/>
            <Footer/>
        </div>
    )
}

export default BrotherPortal