import React from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoaderButton from '../../components/LoaderButton';

import "./ConnectContainerStyles.css";

const ConnectContainer = (props) => {
    return (
        <>
        <div class="button_container">
            <Button size="lg" as={Link} to="/pay">Pay Dues</Button>
            <Button size="lg" as={Link} to="/alumni">Alumni Database</Button>
            <Button size="lg" as={Link} to="/settings">Settings</Button>

        </div>

        <div class="button_container">
            <Button size="lg" href="https://join.slack.com/t/unckappasig/shared_invite/zt-1gjo3v3dx-9GgqQQnrAY2SkDrM8JXjhQ" target="_blank">Slack</Button>
            <Button size="lg" href="https://www.linkedin.com/groups/12444112/" target="_blank">LinkedIn</Button>
        </div>
        {/* <div class="connect_container">
                <h1>Alpha Mu Slack</h1>
                <p>The Alpha Mu Slack is a way for brothers and alumni to easily communicate with one another. The undergraduates have would like to use Slack as a way to foster a stronger connection with our alumni. We have added several different channels for different career paths like finance, tech, medicine, law, etc. so that brothers have the opportunity to easily get in touch with alumni. We encourage all brothers and alumni to sign up and utilize Slack.</p>
                <h4><a href="https://join.slack.com/t/unckappasig/shared_invite/zt-1gjo3v3dx-9GgqQQnrAY2SkDrM8JXjhQ" target="blank">Join Slack</a></h4>
        </div>

        <div class="connect_container">
                <h1>Alpha Mu LinkedIn Group</h1>
                <h4><a href="https://www.linkedin.com/groups/12444112/" target="blank">Join LinkedIn Group</a></h4>
        </div> */}
        </>
    )
}

export default ConnectContainer