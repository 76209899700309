import React from 'react'

// Components
import PrivateNav from '../components/PrivateNav';

import Footer from '../../components/Footer';
import AlumniDatabaseContainer from '../components/AlumniDatabaseContainer';

const AlumniDatabase = () => {
    return (
        <div>
            <PrivateNav/>
            <AlumniDatabaseContainer/>
            <Footer/>
        </div>
    )
}

export default AlumniDatabase