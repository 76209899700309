import React from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoaderButton from '../../components/LoaderButton';

import "./SettingsContainerStyles.css";

const SettingsContainer = (props) => {
    return (
        <div className="Settings">
            <LoaderButton as={Link} to="/settings/password">
                Change Password
            </LoaderButton>
            <LoaderButton as={Link} to="/settings/email">
                Change Email
            </LoaderButton>
            <LoaderButton onClick={() => props.logout()}>
                Logout
            </LoaderButton>
        </div>
    )
}

export default SettingsContainer
