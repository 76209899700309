import React from 'react'
import { Link } from 'react-router-dom';
import "./VideoStyles.css"

import bandVideo from "../assets/home_video_background.mp4";

const Video = () => {
    return (
        <div className='hero'>
            <video autoPlay loop muted id="video">
                <source src={bandVideo} type='video/mp4' />
            </video>
            <div className='content'>
                <div className='content_container'>
                    <h1>Kappa Sigma Alpha Mu</h1>
                    <p>UNC Chapel Hill</p>
                </div>
            </div>
        </div>
    )
}

export default Video